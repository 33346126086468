Vue.component('valueFilterComponent', {
    props: ['filterField'],
    data:function() {
        return {
            display:true
        };
    },
    computed:{
        collections:function(){
            let collection = new Map();
            let items = this.$store.getters.getItems;
            for(let item of items) {
                let value = item[this.filterField.ItemField];
                if (value) {
                    if (!collection.has(value)) {
                        let filter = [];
                        for(let valueSplit of value.split(',') ) {
                            let objectFilter = {};
                            objectFilter.name = 'valueFilter'+uuidv4();
                            objectFilter.condition = `x.${this.filterField.ItemField}.indexOf('${valueSplit}')!=1`;
                            objectFilter.result = true;
                            objectFilter.active = false;
                            filter.push(objectFilter);
                        }
                        collection.set(value,[value, filter]);
                    }
                }
            }
            return Array.from(collection.values());
        }
    },
    methods:{
        launchFilter:function(filters){
            filters.active = true & filters.active;
            this.$store.dispatch('addFilter',[filters.name,filters]);
        }
    },
    template: `<div  class="filter" v-if="collections.length>0">
                    <div>
                        <h5 class="custom-title">
                            {{tr(filterField.ItemField)}}
                            <span v-show="display" @click="display = !display" class="right"><i class="icon fa fa-minus"></i></span>
                            <span v-show="!display" @click="display = !display" class="right"><i class="icon fa fa-plus"></i></span>
                        </h5>
                    </div>
                    <section v-if="display" class="filter-options price-range">
                        <ul>
                          <template v-for="value of collections">
                                <li>
                                    <a v-on:click="launchFilter(value)">{{value[0]}}</a>
                                </li>
                          </template>
                        </ul>
                    </section>
               </div>`,
});
